<template>
  <b-card>
    <b-row>
      <b-col md="12">
        <h2 class="mb-25 font-weight-bolder title">
          {{ $t('CREATING_NEW_USER') }}
        </h2>
        <hr>
      </b-col>
    </b-row>
    <b-alert
      variant="danger"
      :show="errorShow"
    >
      <div class="alert-body font-small-2">
        <p class="mr-50">
          {{ $t(errorInsert) }}
        </p>
      </div>
    </b-alert>
    <!-- form -->
    <validation-observer ref="newUserForm">
      <b-form
        class="mt-4"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('FIRSTNAME')"
              label-for="firstName"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('FIRSTNAME')"
                rules="required"
              >
                <b-form-input
                  v-model="user.firstName"
                  :state="errors.length > 0 ? false:null"
                  name="firstName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('LASTNAME')"
              label-for="lastName"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('LASTNAME')"
                rules="required"
              >
                <b-form-input
                  v-model="user.lastName"
                  :state="errors.length > 0 ? false:null"
                  name="lastName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('EMAIL')"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('EMAIL')"
                rules="required|email"
              >
                <b-form-input
                  v-model="user.email"
                  :state="errors.length > 0 ? false:null"
                  name="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('PHONE_NUMBER')"
                rules="required|min:10"
              >
                <label for="libelle">{{ $t('PHONE_NUMBER') }}: </label>
                <cleave
                  id="phone"
                  v-model="user.phone"
                  type="text"
                  class="form-control"
                  :raw="false"
                  :options="champsformater.numero"
                  maxlength="10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('ROLE')"
              label-for="roles"
            >
              <v-select
                v-model="user.roles"
                name="roles"
                :options="roles"
                :change="updateSociete()"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('COMPANY')"
              label-for="company"
            >
              <v-select
                v-model="user.company"
                name="company"
                :options="companies"
                :state="missingSociete"
                :disabled="disabledCompanies"
              />
              <small
                v-if="missingSociete"
                class="text-danger"
              >
                {{ $t('ERROR_COMPANY_REQUIRED_FOR_ROLE') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('LANGUAGE')"
              label-for="langugage"
            >
              <v-select
                v-model="user.language"
                name="language"
                :options="langue"
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col />
          <b-col cols="6">
            <b-button
              v-if="loading"
              variant="primary"
              type="button"
              block
              disabled
            >
              <b-spinner small /> Loading ...
            </b-button>
            <b-button
              v-else
              variant="primary"
              type="submit"
              block
              @click.prevent="insertUser"
            >
              {{ $t("NEW_USER_BUTTON") }}
            </b-button>
          </b-col>
          <b-col />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BAlert, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import Cleave from 'vue-cleave-component'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.fr'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    Cleave,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      loading: false,
      errorShow: false,
      errorInsert: '',
      champsformater: {
        numero: {
          phone: true,
          delimiter: '',
          phoneRegionCode: 'FR',
          blocks: [10],
        },
      },
      missingSociete: false,
      disabledCompanies: false,
      langue: [
        { value: 'fr', label: 'Français' },
        { value: 'en', label: 'English' },
      ],
      roles: [
        { value: 'ROLE_USER', label: this.$i18n.t('ROLE_USER') },
        { value: 'ROLE_COMPANY_SUPERVISEUR', label: this.$i18n.t('ROLE_COMPANY_SUPERVISEUR') },
        { value: 'ROLE_TECHNICIAN', label: this.$i18n.t('ROLE_TECHNICIAN') },
        { value: 'ROLE_ADMIN', label: this.$i18n.t('ROLE_ADMIN') },
      ],
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: null,
        roles: { value: 'ROLE_USER', label: this.$i18n.t('ROLE_USER') },
        language: { value: 'fr', label: 'Français' },
      },
    }
  },
  computed: {
    companies() {
      return this.$store.getters['companies/getCompanies']
    },
  },
  mounted() {
    if (this.$store.getters['companies/getCompanies'].length === 0) {
      this.$store.dispatch('companies/fetchAll')
    }
    this.missingSociete = false
  },
  methods: {
    updateSociete() {
      if (this.user.roles.value === 'ROLE_TECHNICIAN' || this.user.roles.value === 'ROLE_ADMIN') {
        this.user.company = null
        this.disabledCompanies = 'disabled'
      } else {
        this.disabledCompanies = false
      }
    },
    insertUser() {
      const that = this
      this.loading = true
      this.missingSociete = false
      if ((this.user.roles.value === 'ROLE_USER' || this.user.roles.value === 'ROLE_COMPANY_SUPERVISEUR') && (this.user.company === null || this.user.company.value === '')) {
        this.missingSociete = true
        this.loading = false
      } else {
        this.$refs.newUserForm.validate().then(success => {
          if (success) {
            const newUser = {
              email: that.user.email,
              roles: [that.user.roles.value],
              firstName: that.user.firstName,
              lastName: that.user.lastName,
              phone: that.user.phone,
              language: that.user.language.value,
            }
            if (that.user.company !== null && that.user.company.value) {
              newUser.company = that.user.company.uri
            }
            that.$store.dispatch('users/insert', newUser)
              .then(() => {
                that.loading = false
                that.success(newUser)
              })
              .catch(e => {
                if (e.response && e.response.data) {
                  that.errorInsert = that.$i18n.t(e.response.data)
                } else {
                  that.errorInsert = that.$i18n.t(e)
                }
                that.errorShow = true
                that.loading = false
              })
          } else {
            that.loading = false
          }
        })
      }
    },
    success(user) {
      this.$swal({
        title: this.$i18n.t('USER_CREATED'),
        html:
          `<strong>${this.$t('USER_CREATED_POPUP')} ${user.firstName} ${user.lastName} ${this.$t('USER_CREATED_POPUP2')}</strong><br><br>
          ${this.$t('USER_CREATED_POPUP_EMAIL_SEND')}.`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(isConfirm => {
        if (isConfirm) {
          this.user = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: null,
            roles: { value: 'ROLE_USER', label: this.$i18n.t('ROLE_USER') },
            language: { value: 'fr', label: 'Français' },
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
